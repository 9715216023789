.position-card {
  box-shadow: 1px 4px 4px rgba(62, 101, 136, 0.15),
    -1px -1px 10px rgba(62, 101, 136, 0.08);
  border-radius: 6px;
  width: 128px;
  height: 110px;
  border: 0;
  aspect-ratio: 1 / 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.position-card img {
  max-width: 36px;
}

.position-card p {
  margin: 0;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(2, 128px);
  gap: 1.5rem;
}

.active-watermark-position {
  border: 2px solid #2887af;
}

.business-watermark {
  max-height: 100px;
  object-fit: cover;
  max-width: 100%;
  padding: 0 1rem;
}
