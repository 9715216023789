.progress {
  --progress: 0;

  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: radial-gradient(closest-side, black 65%, transparent 66% 100%),
    conic-gradient(
      var(--theme-secondary) var(--progress),
      var(--theme-tertiary) 0
    );
}

.toolbar-container {
  position: fixed;
  z-index: 20;
  right: 10px;
  bottom: 48px;
}

.video-toolbar-container {
  margin-bottom: 64px;
}

.toolbar {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.toolbar li {
  cursor: pointer;
  padding: 0.5rem 0.625rem;
}

.toolbar li img {
  width: 24px;
}

@media screen and (max-width: 767px) {
  .toolbar-container {
    top: 0px;
    right: 12px;
    height: 50px;
  }

  .video-toolbar-container {
    margin-bottom: 0;
    margin-top: 46px;
  }

  .toolbar {
    flex-direction: row;
    margin-top: 9.5px;
  }

  .toolbar li {
    padding: 0.5rem;
  }
}
