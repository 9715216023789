/* 
- xs: 480px and above
- sm: 576px and above
- md: 768px and above
- lg: 992px and above
- xl: 1200px and above
*/

/* width and heights */

.w-fit {
  width: fit-content;
}

.w-45 {
  width: 45% !important;
}

.min-w-auto {
  min-width: auto !important;
}

.h-fit {
  height: fit-content !important;
}

/* font sizes */

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-19 {
  font-size: 19px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-45 {
  font-size: 45px !important;
}

/* gaps */

.gap-12 {
  gap: 12px !important;
}

/* outlines */

.outline-none {
  outline: none !important;
}

/* line heights */

.lh-12 {
  line-height: 1.2;
}

/* paddings */

.px-36 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

/* colors */

.color-theme {
  background: -webkit-linear-gradient(#2887af, #3e6588);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 480px) {
  .font-xs-13 {
    font-size: 13px !important;
  }
}

@media (min-width: 576px) {
  .font-sm-10 {
    font-size: 14px !important;
  }

  .font-sm-15 {
    font-size: 15px !important;
  }

  .font-sm-20 {
    font-size: 20px !important;
  }

  .font-sm-24 {
    font-size: 24px !important;
  }

  .font-sm-25 {
    font-size: 25px !important;
  }
}

@media (min-width: 768px) {
  .position-md-relative {
    position: relative !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .font-md-10 {
    font-size: 10px !important;
  }

  .font-md-11 {
    font-size: 11px !important;
  }

  .font-md-12 {
    font-size: 12px !important;
  }

  .font-md-13 {
    font-size: 13px !important;
  }

  .font-md-14 {
    font-size: 14px !important;
  }

  .font-md-15 {
    font-size: 15px !important;
  }

  .font-md-16 {
    font-size: 16px !important;
  }

  .font-md-17 {
    font-size: 17px !important;
  }

  .font-md-18 {
    font-size: 18px !important;
  }

  .font-md-19 {
    font-size: 19px !important;
  }

  .font-md-20 {
    font-size: 20px !important;
  }

  .font-md-22 {
    font-size: 22px !important;
  }

  .font-md-24 {
    font-size: 24px !important;
  }

  .font-md-30 {
    font-size: 30px !important;
  }
}

@media (min-width: 992px) {
  .font-lg-18 {
    font-size: 18px !important;
  }

  .font-lg-20 {
    font-size: 20px !important;
  }
}
