.mobile-cover-upload-card {
  height: 360px;
}

.sort-div {
  padding-left: 3rem;
  margin-top: 1.5rem !important;
}

@media screen and (max-width: 992px) {
  .sort-div {
    padding-left: 12px;
    margin: 3rem 0 10rem;
  }
}
