.radio-para {
	font-size: 0.8rem !important;
	font-weight: 600 !important;
	margin: 0;
	margin-top: 4px;
}

.radio-para:first-of-type {
	margin-top: 6px;
}

.radio-group-icons {
	height: 15px !important;
	width: 15px !important;
	margin-bottom: 2px;
}
