.commentBoxModal {
  z-index: 10000 !important;
}
.commentBoxModal .modal-header {
  border-bottom: none !important;
}
.commentTextarea {
  border: 1px solid rgb(239 239 239);
  box-shadow: 1px 4px 4px rgba(62, 101, 136, 0.15);
}
.submit-btn {
  display: flex;
  justify-content: center;
}
.submit-btn button {
  padding: 5px 0px;
  width: 40%;
}
