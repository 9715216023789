.subscription-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  overflow-y: scroll;
}

.subscriptionModal .modal-lg {
  width: 95%;
  max-width: 1188px !important;
}

.subscriptionModal .modal-body {
  background: #ffffff !important;
  border: none;
  padding: 0;
}

.subscription-header {
  width: 100%;
  position: absolute;
  z-index: 99;
  top: 0;
}

.subscription-plan__details {
  max-width: 40%;
  width: 40%;
  padding: 24px 48px 16px 48px;
  /* height: 100vh; */
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.subscription-plan__details header p.color-primary {
  font-size: 12px;
}

.subscription-plan__details::-webkit-scrollbar {
  display: none;
}

.subscription-plan__hero {
  width: 60%;
  max-width: 60%;
  /* height: 100vh; */
}

.subscription-plan__hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.payment-feature {
  font-size: 15px;
}

.plan-feature span {
  font-size: 12px;
}

.billing-details span {
  font-size: 12px;
}

.TC-PP p {
  font-size: 12px;
}

.upgrade-title {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.pricing-arrow {
  border: none !important;
  background: transparent !important;
}

.card-tertiary {
  background-color: var(--theme-tertiary);
}

@media screen and (max-width: 480px) {
  .upgrade-title {
    font-size: 16px !important;
    font-weight: 600 !important;
  }

  .pricing-wrapper {
    padding: 16px 8px !important;
  }

  .pricing-control-wrapper .check-box {
    font-size: 12px !important;
  }

  .left-pricing-arrow {
    left: -1.25rem;
  }

  .right-pricing-arrow {
    right: -1.25rem;
  }
}

@media screen and (max-width: 768px) {
  .contact-us span {
    font-size: 12px !important;
  }
}
