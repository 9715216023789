.selected-users-wrapper {
  padding: 0 !important;
  position: relative;
  max-width: 47% !important;
  width: 47% !important;
  margin: 0px 5% 0px 1.5%;
}

.selected_users-list {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  overflow-x: scroll;
}

.selected_users-list .selected_user {
  display: flex;
  width: 80px;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
}

.selected_users-list .selected_user img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 8px;
}

.selected_users-list .selected_user h4 {
  text-align: center;
  text-overflow: ellipsis;
}

.participants-caret {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: inherit;
  height: 44.8px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.left-participants-caret {
  left: -30px;
}

.right-participants-caret {
  right: -30px;
}

.left-participants-caret img {
  transform: rotate(180deg);
}

.tooltip :global(.tooltip-inner) {
  max-width: 220px;
}

@media screen and (max-width: 992px) {
  .selected-users-wrapper {
    max-width: 90% !important;
    width: 90% !important;
    margin: 0px auto 0px auto;
  }

  .right-participants-caret {
    right: -20px;
  }

  .left-participants-caret {
    left: -20px;
  }
}
