.delete-input {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  padding: 0 10px;
  border: 1px solid rgba(0, 0, 0, 0.6);
}

.goodbye-wave {
  height: 47px;
  width: 47px;
}
