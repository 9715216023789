.photo-item {
  position: relative;
}

.folder-imgs-image {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  position: relative;
}

.gallaryPage.dark .folder-imgs-image {
  background-color: #333;
}
