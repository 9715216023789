.events-landing h2 {
  font-size: 26px;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
}

.events-nav {
  margin-bottom: 1rem;
}

.events-navlinks li {
  padding: 0.25rem 0;
}

.events-navlinks li a {
  color: var(--theme-secondary) !important;
}

.events-logo {
  max-height: 72px;
  max-width: 144px;
}

.details,
.sponsors,
.organizers,
.achievements {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.slide {
  --bs-aspect-ratio: 37.5%;
  border-radius: 1rem;
  overflow: hidden;
}

.slide > div {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slide > div > div {
  backdrop-filter: blur(12px);
  height: 100%;
  width: 100%;
}

.slider :global(.slick-arrow) {
  height: 2rem !important;
  width: 2rem !important;
  z-index: 1;
}

.slider :global(.slick-arrow)::before {
  font-size: 2rem;
}

.slider :global(.slick-prev) {
  left: 1rem;
  z-index: 1;
}

.slider :global(.slick-next) {
  right: 1rem;
}

.details-card {
  width: 100%;
  height: 100%;
  background-color: var(--gray6);
  border-radius: var(--border-radius-tertiary);
  padding: 1.25rem;
}

.details-item {
  margin-bottom: 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
}

.details-item img {
  flex-basis: 16px;
  flex-shrink: 0;
}

.sponsors-grid,
.organizers-grid,
.achievements-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2.5rem;
}

.sponsors-grid img {
  flex-shrink: 0;
  flex-basis: 256px;

  max-width: 256px;
  max-height: 128px;
  object-fit: contain;
}

.organizer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.organizer img {
  height: 175px;
  width: 175px;
  border-radius: 50%;
}

.achievements-grid div {
  flex-basis: 240px;
}

@media (max-width: 768px) {
  .events-logo {
    max-height: 48px;
    max-width: 96px;
  }

  .events-landing h2 {
    font-size: 20px;
    text-align: center !important;
  }

  .details,
  .sponsors,
  .organizers,
  .achievements {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .slider :global(.slick-arrow) {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }

  .slider :global(.slick-arrow)::before {
    font-size: 1.5rem;
  }

  .sponsors-grid,
  .organizers-grid,
  .achievements-grid {
    gap: 1.25rem;
  }

  .sponsors-grid img {
    flex-basis: 128px;
    max-width: 128px;
    max-height: 64px;
  }

  .organizer img {
    height: 120px;
    width: 120px;
    border-radius: 50%;
  }
}
