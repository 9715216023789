.download-page {
    background-color: #f8f9fa;
}

.download-page .download-page-content {
    width: 90vw;
    margin: 0 auto;
    background-color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.download-folders-list {
    width: 50vw;
    margin: 0 auto;
}

.download-status {
    font-size: 13px;
}

.download-status.success {
    color: green
}

.download-status.success {
    color: green
}

.download-status.error {
    color: red
}

.download-status.duplicate {
    color: rgb(178, 131, 45)
}

.download-retry {
    color: red;
    width: '110px';
    cursor: pointer;
}

.download-folders-list .download-or {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid rgba(196, 196, 196, 0.6);
    line-height: 0.1em;
}

.download-folders-list .download-or span{
    color: rgba(0, 0, 0, 0.4);
    background-color: #fff;
    padding: 0 7px;
}