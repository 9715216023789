.current-active-plan {
  border: 3px solid #2887af;
  overflow: hidden;
}

.current-plan {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0 !important;
  background-color: #2887af;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  color: #fff;
}

.discount-badge {
  height: 48px;
  width: 48px;
  position: absolute;
  top: -4px;
  right: -50px;
}

.pricing-striked {
  position: absolute;
  top: -22px;
  font-size: 15px;
  font-weight: 500;
  text-decoration: line-through;
}

@media (max-width: 480px) {
  .discount-badge {
    top: -6px;
    right: -38px;
  }
}
