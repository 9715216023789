.groupViewCard {
  margin-top: 20px;
}

.afterScrollMenu {
  position: absolute;
  left: 30%;
  right: 30%;
  /*width: 100%;*/
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gotToTop {
  position: absolute;
  width: 20px;
  height: 30px;
  top: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 1px 5px 0 #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.gotToTop img {
  transform: rotate(180deg);
  width: 14px;
}

.afterScrollMenu .header-user img {
  width: 16px;
  margin-left: 10px;
}

.gallary-header-container {
  position: relative;
}

.gallary-header-container ul li {
  position: relative;
}

.caret {
  position: absolute;
  bottom: 0;
  background-color: inherit;
  z-index: 1;
  height: 100%;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-caret {
  z-index: -1 !important;
}

.gallery-header-left-caret {
  left: 0;
}

.gallery-header-left-caret img {
  transform: rotate(180deg);
}

.gallery-header-right-caret {
  right: 0;
}

.gallery-header-caret-dark {
  background: black;
  border-bottom: 1px solid #464646 !important;
}

.gallery-header-caret-light {
  background: white;
  border-bottom: 1px solid #dee2e6 !important;
}

.group-top-text {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px !important;
  width: auto;
  white-space: nowrap;
}

.group-top-text-wrapper {
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.model-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #3a3a3a;
}

.model-group-type {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #3e6588;
}

.model-group-type-qr {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #3e6588;
}

.model-group-created-by {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #3e6588;
}

.model-box-name {
  width: 168px;
  height: 52px;
  background: #ffffff;
  box-shadow: 1px 4px 4px rgba(62, 101, 136, 0.15),
    -1px -1px 10px rgba(62, 101, 136, 0.08);
  border-radius: 6px;
}

.model-joined-user {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #3a3a3a;
}

@media (min-width: 768px) {
  .GalleryHeadingLeftSetting:hover {
    width: 110px;
  }

  .GalleryHeadingLeftShare:hover {
    width: 90px;
  }

  .GalleryHeadingLeftDownload:hover {
    width: 145px;
  }

  .GalleryHeadingLeftSelfie:hover {
    width: 105px;
  }

  .GalleryHeadingLeftFolders:hover {
    width: 135px;
  }
}
