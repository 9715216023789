.shareModal {
  z-index: 10000 !important;
}

.shareContainer {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
}

.shareContainer img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
