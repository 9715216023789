.dropdown {
  font-size: 13px;
  width: fit-content;
  height: 40px;
  font-weight: 500;
  color: #2887af !important;
  background: none !important;
  box-shadow: 1px 4px 4px rgba(62, 101, 136, 0.15),
    -1px -1px 10px rgba(62, 101, 136, 0.08);
  text-align: left;
  padding-left: 1.25rem;
  position: relative;
  min-width: 200px;
}

.dropdown:active,
.dropdown:hover,
.dropdown:focus {
  background: none !important;
  color: #2887af !important;
  box-shadow: 1px 4px 4px rgba(62, 101, 136, 0.15),
    -1px -1px 10px rgba(62, 101, 136, 0.08) !important;
}

.dropdown + div {
  border: 0;
  box-shadow: 1px 4px 4px rgba(62, 101, 136, 0.15),
    -1px -1px 10px rgba(62, 101, 136, 0.08);
}

.dropdown + div .dropdown-item {
  color: #00000066 !important;
  width: 100% !important;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500 !important;
  height: 40px !important;
  transition: background-color 0.3s ease-out, color 0.3s ease-out;
}

.dropdown + div .dropdown-item img {
  height: 24px !important;
  width: 21px !important;
  margin-right: 12px !important;
}

.dropdown + div .dropdown-item:global(.active),
.dropdown + div .dropdown-item:hover {
  color: var(--theme-secondary) !important;
  background-color: #dbeff8 !important;
}

.dropicon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: 10px !important;
  width: 16px !important;
  margin-right: 0 !important;
}
