.premiumBadge {
  user-select: none;
  padding: 4px 8px;
  border-radius: var(--border-radius-secondary);
  background-color: var(--gray2);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  font-size: 15;
  font-weight: 500;
  color: var(--text-secondary);
  margin: 0;
  line-height: 1;
}

.icon {
  height: 18px !important;
  width: 18px !important;
}

.psUpload {
  bottom: 8.75rem;
}

.checkoutIcon {
  position: fixed;
  bottom: 5.5rem;
  right: 1.5rem;
  z-index: 99;
  border-radius: 48px;
  background: #2887afb3;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 36px;
  padding: 0 16px;
}

.checkoutIcon img {
  height: 24px;
  width: 24px;
}

/* for badge position on images */

.listings {
  position: absolute;
  bottom: 12px;
  left: 12px;
  z-index: 3;
}

.fullsize {
  position: fixed;
  bottom: 56px;
  left: 16px;
}

.hoverOptions {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  z-index: 3;
  bottom: 0;
  width: 100%;
  height: 53px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  transition: opacity 0.25s;
}

.cartIcon,
.favIcon,
.downloadIcon {
  pointer-events: auto;
  position: absolute;
  height: 17px;
  width: 17px !important;
  right: 14px;
  bottom: 14px;
}

.downloadIcon.downloadProgress {
  height: 17px !important;
}

.cartIcon {
  height: 17px;
  right: 76px;
}

.favIcon {
  height: 17px;
  right: 45px;
}

.cartIcon:has(+ .downloadIcon) {
  right: 45px;
}

:global(.folder-imgs-image):hover .hoverOptions {
  opacity: 1;
}

.hoverOptions:hover {
  opacity: 1;
}

.selected {
  outline: 3px solid var(--theme-secondary);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

.image {
  height: 100%;
  width: 100%;
  transform: scale(0);
  transition: all 0.3s ease-out;
  object-fit: cover;
  z-index: 2;
}

.selected .image {
  opacity: 50%;
}

:global(.gallaryPage.sizeSmall .photo-item).selectMode,
:global(.gallaryPage.sizeLarge .photo-item).selectMode {
  padding: 6px !important;
  width: calc(100% / 6) !important;
}

.selectMode
  :global(.folder-imgs-image:not(:has(> .gallery-img-spinner-loader))) {
  height: auto !important;
}

.imageSelector {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 1.125rem;
  left: 1.125rem;
  z-index: 5;
  border: 2px solid var(--theme-secondary);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.75);
  transition: opacity 0.3s ease-out;
  opacity: 0;
}

.checkBox {
  display: none;
}

.checkBox:checked + .imageSelector {
  background-color: var(--theme-secondary);
  background-image: url(../../../../../public/assets/images/icons/check.png);
  background-size: contain;
}

:global(.photo-item):hover .imageSelector {
  opacity: 1;
}

@media (pointer: coarse) {
  .checkBox,
  .imageSelector,
  .hoverOptions {
    display: none;
  }
}

@media (max-width: 768px) {
  .icon {
    height: 12px !important;
    width: 12px !important;
  }

  .fullsize {
    bottom: 60px;
    border-radius: 50%;
  }

  .fullsize .premiumBadge {
    padding: 6px;
    border-radius: 50%;
  }

  .fullsize .premiumBadge .icon {
    height: 20px !important;
    width: 20px !important;
  }

  .fullsize .premiumBadge span {
    display: none;
  }
}
