.folder-holder {
  font-weight: 600;
  color: #2887af;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  height: 70px;
  display: flex;
  align-items: center;
  font-size: 15px;
  max-width: clamp(300px, 88%, 900px);
  user-select: none;
  gap: 1rem;
}

.folder-holder:hover {
  background-color: #f5f5f5;
}

.folder-holder:first-of-type {
  border-top: 1px solid #e0e0e0;
}

.folder-holder span {
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
