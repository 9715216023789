.mobile-pricing {
  overflow: hidden;
  position: relative;
  margin-bottom: 88px; /* height of the mobile contact us */
}

.mobile-pricing .current-plan {
  padding: 0.3rem 1rem !important;
  font-size: 14px;
}

.mobile-pricing .pricing-header,
.mobile-pricing .contact-us,
.mobile-pricing .item-package-button,
.mobile-pricing .free-plan-wrapper {
  display: none;
}

.mobile-header-upgrade {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 12px 10px;
}

.mobile-header-upgrade img {
  height: 24px;
  margin-right: 4px;
}

.mobile-header-upgrade span {
  font-size: 15px;
  font-weight: 600;
  color: #3e6588;
}

.mobile-pricing .pricing-item-wrapper {
  padding: 0;
}

.mobile-pricing .pricing-list-item {
  padding: 18px 16px 0px;
  height: 425px;
  min-height: unset;
  max-width: 310px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
}

.mobile-pricing .pricing-wrapper {
  padding: 16px 8px 8px !important;
}

.mobile-pricing label {
  margin-bottom: 0 !important;
}

.mobile-pricing .check-box {
  margin-bottom: 8px;
}

.mobile-contact-us {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 0 10px;
  gap: 0.5rem;
  padding: 0.5rem;
  position: fixed;
  background-color: white;
  bottom: 0;
  width: 100%;
}

.or-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.or-div::before,
.or-div::after {
  content: "";
  border-left: 1px solid #a2a2a2;
  height: 20px;
}

.or-div::after {
  margin-top: 0.125rem;
}

.or-div::before {
  margin-bottom: 0rem;
}

.mobile-contact-us img {
  height: 24px;
}

.mobile-contact-us p {
  font-size: 15px;
  font-weight: 600;
  color: #3e6588;
}

.mobile-support {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 1rem 1rem;
}

.mobile-support input,
.mobile-support select,
.mobile-support textarea {
  height: 42px;
  width: 100%;
  border: 0.49px solid rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 0 10px;
}

.mobile-support select:focus {
  box-shadow: none;
  border: 2px solid black;
  outline-offset: 0px;
}

.mobile-support textarea {
  width: 100%;
  height: unset;
  padding: 10px;
}

.mobile-support .countryJson {
  margin: 0 !important;
}

.mobile-support .countryJson .selected {
  margin-left: 0;
}

.mobile-support input::placeholder,
.mobile-support textarea::placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px !important;
}

.mobile-support .contactus-header {
  display: none;
}

.mobile-support .contact-form-gray-text {
  font-size: 11px !important;
}

.mobile-support .contact-form-gray-text br {
  display: none;
}

.mobile-pricing .item-package-features {
  overflow: auto;
  padding-left: 10px;
  margin-bottom: 31px;
}

.mobile-pricing .item-package-features::-webkit-scrollbar {
  width: 7px;
}

@media screen and (min-width: 768px) {
  .mobile-support .contactus-header {
    display: block;
    font-size: 1.5rem;
    font-weight: 600;
    color: #3e6588;
    margin-bottom: 0.75rem !important;
  }
}

@media screen and (max-width: 576px) and (min-width: 377px) {
  .mobile-pricing .container .right-pricing-arrow {
    right: 50%;
    transform: translateX(185px);
  }
  .mobile-pricing .container .left-pricing-arrow {
    left: 50%;
    transform: translateX(-185px);
  }
}

@media screen and (max-width: 376px) {
  .mobile-pricing .container .right-pricing-arrow {
    right: -0.25rem;
  }
  .mobile-pricing .container .left-pricing-arrow {
    left: -0.25rem;
  }
  .mobile-pricing .item-package-features::-webkit-scrollbar {
    width: 5px;
  }
}
