.pricing-arrow {
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: white;
    height: 3rem;
    aspect-ratio: 1/1;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.not-disabled-pricing-arrow {
    border: solid 3px #2887af;
}

.left-pricing-arrow {
    left: -2rem;
}
.right-pricing-arrow {
    right: -2rem;
}

.left-pricing-arrow svg{
    transform: rotate(180deg);
}

.disabled-pricing-arrow {
    border: solid 3px #a9e2fa;
}