.balance-card {
  background: #ffffff;
  box-shadow: 1px 4px 4px rgba(62, 101, 136, 0.15),
    2px 2px 10px rgba(62, 101, 136, 0.15);
  border-radius: 12px;
  padding: 1rem 1.5rem;
}

.balance {
  font-size: 30px !important;
  font-weight: 600 !important;
  background: linear-gradient(268.75deg, #3e6588 3.84%, #2887af 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 0;
}

.quick-add {
  background: #ffffff;
  border: 1px solid #2887af !important;
  border-radius: 6px;
  color: #3e6588;
  font-weight: 600;
  padding: 0.5rem 0;
  font-size: 15px;
  width: 100px;
}

.box {
  border-radius: 12px;
  background: #f7fcfe;
  box-shadow: 2px 2px 10px 0px rgba(62, 101, 136, 0.15),
    1px 4px 4px 0px rgba(62, 101, 136, 0.15);
  padding: 16px 20px;
  max-width: 490px;
  margin-bottom: 24px;
  margin-top: 16px;
}

.box td {
  font-size: 15px;
  font-weight: 500;
  height: 36px;
}

.box tr {
  height: 36px;
}

.box td img {
  height: 24px;
  width: 24px;
}

.box td:first-child {
  width: 36px;
}

.box td:nth-child(2) {
  width: 100px;
}

.credit-input {
  display: block;
  margin-bottom: 8px;
  position: relative;
}

.credit-input > input {
  height: 55px;
}

.credit-input > input[type="number"]::-webkit-outer-spin-button,
.credit-input > input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0;
}

.credit-input > input[type="number"] {
  /* reset initial style */
  -moz-appearance: spinner-textfield;
}

.credit-input::before {
  content: attr(data-currency-symbol);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  font-size: 18px;
  color: black;
  font-weight: 600;
}

.credit-input::after {
  content: attr(data-credits);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 125px;
  color: #00000066;
  font-size: 15px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .quick-add {
    width: 75px;
  }
}
