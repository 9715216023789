button {
  border: none !important;
}

button:focus {
  box-shadow: none !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #ddd;
}

::-webkit-scrollbar-thumb {
  background: #2887af;
}

.dropdown > div {
  border: 0;
  box-shadow: 1px 4px 4px rgba(62, 101, 136, 0.15),
    -1px -1px 10px rgba(62, 101, 136, 0.08);
  overflow: hidden;
}

.folder {
  overflow-x: scroll;
  overflow-y: hidden;
  font-weight: 600 !important;
  scrollbar-width: none;
}
.folder::-webkit-scrollbar {
  display: none;
}

.loading {
  color: #3e6588;
  padding: 20px;
  font-style: italic;
}

.shine-effect {
  /* background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%) !important; */
  background-repeat: no-repeat;
  background-color: #fafafa;
  background-size: 800px 104px;
  width: 100%;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 3s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-color: white;
  }

  50% {
    background-color: #3e6588;
  }

  100% {
    background-color: white;
  }
}

.gallaryOverlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  touch-action: manipulation;
  height: 150vh;
}

.gallaryOverlayBG {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #0b0b0b;
}

.overlayMain {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.overlayHeader {
  float: left;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  color: #ffffff;
}

.overlayHeader svg {
  cursor: pointer;
}

.overlayBody .slideArrows {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgb(137 131 131 / 26%);
}

.imageIndex {
  position: absolute;
  left: 20px;
  bottom: 40px;
}

.bodyImage {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  height: 90vh;
  overflow: auto;
}

.image_count {
  position: fixed;
  top: 0;
  left: 60px;
  padding-top: 10px;
  color: white;
  font-family: var(--font-sans-serif);
  height: 40px;
}

.image_name {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 16px 10px;
  color: white;
  font-family: var(--font-sans-serif);
  /* height: 40px; */
  text-align: center;
  font-size: 12px;
  width: 100%;
  text-overflow: inherit;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(7, 7, 7, 0.77) 87.81%
  ) !important;
}

.image_navigation .modalImage {
  transition: transform 0.25s ease;
}

.modalImage {
  /* position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
  max-height: 90vh;
  max-width: calc(100% - 100px) !important;
  height: 100% !important;
  object-fit: contain;
  /* transform-origin: 100% 100%; */
}

.image_navigation {
  width: 100vw;
  height: 100%;
  margin: auto;
  user-select: none;
  position: relative;
}

.navigate_icons {
  z-index: 1;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  box-sizing: border-box;
}

.gallary-modal-back-button {
  position: fixed;
  top: 16px;
  left: 16px;
  padding-top: 10px;
  color: white;
  font-family: var(--font-sans-serif);
  height: 32px;
}

.nav_icons {
  height: 40px;
  width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: white;
  border-radius: 99px;
}

.navigate_icons_left {
  left: 10px;
}

.navigate_icons_right {
  right: 10px;
}

.cover_image-general img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.overRideHeader.bg-light {
  position: absolute !important;
  width: 100%;
  background-color: transparent !important;
  z-index: 10;
}

.overRideHeader.bg-transparent {
  position: absolute !important;
  width: 100%;
  background-color: transparent !important;
}

.temp-gallary-image {
  width: 100%;
}

.selected {
  width: 60px;
  padding: 2px;
  border-radius: 4px;
  border: 1.5px solid rgb(41 135 175);
}

.countryJson {
  margin-left: -72px;
  margin-top: 20px;
}

.loading {
  width: 100vw;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
  position: relative;
}

.loading span {
  font-weight: bold;
  font-weight: 900;
  color: burlywood;
  text-shadow: 8px 8px 6px black;
}

.dot-flashing {
  position: relative;
  top: 8px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: black;
  /* color: #3e6588; */
  color: #edf6fa;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: black;
  /* color: #3e6588; */
  color: #edf6fa;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing {
  position: relative;
  top: 8px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: black;
  /* color: #3e6588; */
  color: #edf6fa;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: black;
  /* color: #3e6588; */
  color: #edf6fa;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: black;
  color: #edf6fa;
  /* color: #3e6588; */
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: black;
  }

  50%,
  100% {
    /* background-color: #3e6588; */
    background-color: #edf6fa;
  }
}

.photo-list {
  width: 100%;
  list-style: none;
}

.photo-item {
  display: flex;
  padding: 1px;
  cursor: pointer;
  user-select: none;
}

.photo-item img {
  display: flex;
  z-index: 2;
  width: 100%;
  object-fit: cover;
}

.gallery-end {
  color: #3e6588;
  font-style: italic;
  padding: 48px 48px 24px 48px;
  font-weight: 700;
  font-size: 24px;
}

.warning-icon {
  width: 20px;
  margin-left: 10px;
}

.warning-error {
  color: #c02727 !important;
}
.lightbox {
  transition: opacity 1000ms ease-in;
}
.lightbox-caption .yarl__slide_description {
  text-align: center;
  width: 80%;
  margin: auto;
}
.yarl__toolbar {
  flex-direction: column;
  position: absolute;
  inset: 0 0 auto auto;
  display: flex;
  justify-content: flex-end;
  padding: var(--yarl__toolbar_padding, 8px);
  margin-top: 32px;
}

.yarl__toolbar .yarl__button {
  padding: 6px;
}

.yarl__slide_captions_container {
  font-size: 12px !important;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(7, 7, 7, 0.77) 87.81%
  ) !important;
}

.yarl__slide {
  padding: 0 !important;
}

.yarl__slide_description {
  text-align: center;
}

@media (max-width: 767px) {
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  .yarl__toolbar {
    position: fixed !important;
    bottom: 40px !important;
  }
}
