.left-side-image-back {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.join-group-cover {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.join-group-form {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
.box-join {
  position: absolute;
  color: black;
  background-color: rgba(255, 255, 255, 0.85);
  opacity: 1;
  display: flex;
  height: 60%;
  width: 55%;
  justify-content: space-around;
  border-radius: 10px;
  flex-direction: column;
  padding: 0px 20px;
}
.box-center {
  display: flex;
  justify-content: center;
}
.group-header {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.3;
  text-align: center;
  color: #070707;

  overflow: hidden;
  white-space: normal;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.photo-count {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #3e6588;
  margin: 0;
}
.photographer-logo {
  max-width: 85px;
  max-height: 85px;
}
.pg-logo {
  height: unset !important;
  max-height: 90px;
  max-width: 150px;
  aspect-ratio: 5/3;
  margin: auto;
  object-fit: contain;
}
.join-group-form {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.continue-button {
  height: 50px;
  width: 100%;
  background: linear-gradient(268.75deg, #3e6588 3.84%, #2887af 100%);
  border-radius: 6px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}
.sub-header {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #a2a2a2;
}
.main-header {
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  background: linear-gradient(268.75deg, #3e6588 3.84%, #2887af 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.ucode-input input {
  border: 1.5px solid #2887af;
  border-radius: 6px;
  height: 50px;
  width: 340px;
  padding: 20px;
}
.ucode-input input::placeholder {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 600;
  font-size: 12px;
}
.join-info {
  position: absolute;
  top: 0;
  right: 80px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(28px);
  border-radius: 6px;
  width: 458px;
  height: 169px;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  backdrop-filter: blur(28px);
}
.grey-text-box {
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(100px);
  position: absolute;
  width: 550px;
  right: 40px;
  top: 50px;
}
.grey-text-box p {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #ffffff;
}

@media (max-width: 768px) {
  .group-header {
    -webkit-line-clamp: 2;
  }
}

@media (max-width: 992px) {
  .box-join {
    width: 70%;
  }
}

@media (min-width: 512px) and (max-width: 768px) {
  .box-join {
    width: min(460px, 75%);
    height: 280px;
    justify-content: space-between;
    padding: 30px;
  }
  .join-group-cover {
    height: 60vh;
    object-fit: cover;
  }
  .photographer-logo {
    margin-right: 10px;
    margin-bottom: 0px;
  }
  .pg-logo {
    max-height: 75px;
    max-width: 125px;
  }
  .join-group-form {
    margin-top: 100px;
  }
  /* fonts */
  .group-header {
    font-size: 21px;
  }
  .photo-count {
    font-size: 13px;
  }
}
@media (min-width: 320px) and (max-width: 512px) {
  .box-join {
    width: 344px;
    height: 250px;
    justify-content: space-between;
    padding: 20px;
    bottom: 16px;
  }
  .join-group-cover {
    height: 344px;
    object-fit: cover;
  }
  .join-group-form {
    height: 100%;
  }
  .join-group-form {
    margin-top: 100px;
  }
  .photographer-logo {
    margin-right: 10px;
    margin-bottom: 0px;
  }
  .pg-logo {
    max-height: 66px;
    max-width: 110px;
  }
  /* fonts */
  .group-header {
    font-size: 21px;
  }
  .photo-count {
    font-size: 13px;
  }
}
@media (max-width: 320px) {
  .box-join {
    width: 260px;
    height: 230px;
    justify-content: space-between;
    padding: 20px;
  }
  .join-group-cover {
    height: 344px;
    object-fit: cover;
  }
  .join-group-form {
    height: 100%;
  }
  .join-group-form {
    margin-top: 100px;
  }
  .photographer-logo {
    margin-right: 10px;
    margin-bottom: 0px;
  }
  .pg-logo {
    max-height: 42px;
    max-width: 70px;
  }
  /* fonts */
  .group-header {
    font-size: 21px;
  }
  .photo-count {
    font-size: 13px;
  }
}
