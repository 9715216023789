.mode-select {
  border: none;
  box-shadow: 1px 4px 4px rgba(62, 101, 136, 0.15),
    -1px -1px 10px rgba(62, 101, 136, 0.08);
  border-radius: 6px;
  padding: 1rem 2rem;
  width: fit-content;
  cursor: pointer;
  user-select: none;
}

.mode-select img {
  height: 48px;
  width: 48px;
  margin: auto;
  margin-bottom: 0.5rem;
}

.active {
  outline: 2px solid #2196f3;
}

.new-icon {
  position: absolute;
  top: -9px;
  right: -9px;
  height: 28px;
  width: 28px !important;
  background-color: white;
  border-radius: 50%;
}

.highlight {
  animation: 0.5s ease-in 5 highlight-button;
  transition: outline-offset 0.5s ease-in-out;
}

.search {
  min-width: 225px;
}

.search img {
  display: inline-block;
  height: 20px;
  margin-left: -30px;
  cursor: pointer;
}

@keyframes highlight-button {
  0% {
    outline: 2px solid #2887af;
    outline-offset: 1px;
  }
  50% {
    outline: 2px solid #2887af;
    outline-offset: 5px;
  }
  100% {
    outline: 2px solid #2887af;
    outline-offset: 1px;
  }
}

@media screen and (max-width: 768px) {
  .btn-grp button:first-of-type {
    order: 3;
  }
}

@media screen and (max-width: 576px) {
  .search {
    min-width: unset;
    width: 100%;
  }
}
