.uploadSelector input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.uploadSelector label {
  padding: 4px 10px;
  background-color: white;
  height: 100%;
  border: 1px solid #2887af;
  color: #3e6588;
  cursor: pointer;
  font-weight: 500;
}

.uploadSelector label:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.uploadSelector label:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.uploadSelector label.selected {
  color: white !important;
  background-color: #2887af !important;
}

.listStyle {
  border: 1px dashed #e8e8e8;
}

.iconHolder {
  padding-right: 0.5rem;
}

.footerContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  /* width: calc(100% - 4.75rem); */
}

.sortBy {
  position: absolute;
  right: 21%;
  top: 3.25%;
  font-weight: 600;
  font-size: 15px;
}

.sort-icon {
  width: 44px !important;
}

.drop-area {
  composes: card-height from global;
  overflow: hidden;
  margin: 1rem 0.5rem 0;
  overflow: hidden;
  height: 335px !important;
  max-height: 100% !important;
}

.da-modal :global(.modal-dialog) {
  max-width: 860px !important;
}

.da-modal :global(.modal-content) {
  height: 560px !important;
}

.dropdown button {
  padding: 0.25rem;
  width: 100%;
  background-color: var(--bg-tertiary);
}

.dropdown img {
  height: 26px;
  object-fit: contain;
}

.dropdown :global(.dropdown-menu) {
  min-width: unset;
  width: fit-content;
}

.dropdown :global(.dropdown-item) {
  width: fit-content;
  padding: 0.2rem 0.75rem;
  line-height: 1.75 !important;
}

.dropdown :global(.dropdown-item) img {
  /* override global */
  width: unset !important;
  height: 26px !important;
  margin-right: 0 !important;
}
