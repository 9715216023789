.input {
  display: inline-block;
  border-style: solid;
  border-radius: var(--border-radius-primary);
  padding: 0 0.5rem;
  width: 100%;
  font-weight: 500;
  color: var(--text-primary);
}

.primary {
  border-color: var(--theme-secondary);
}

.secondary {
  border-color: var(--gray3);
}

.sm {
  height: 40px;
}

.md {
  height: 50px;
}

.disabled {
  border-color: var(--disabled);
}

.valid {
  border-width: 2px !important;
  border-color: var(--green);
}

.invalid {
  border-width: 2px !important;
  border-color: var(--red1);
}

.search {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0.5rem;
}

.search::after {
  content: "";
  height: 18px;
  width: 18px;
  background-image: url("../../../../public/assets/images/icons/search.png");
  background-size: contain;
  margin-left: -1.75rem;
}

.search input {
  max-width: 300px;
  padding-right: 2.5rem;
  border: 2px solid transparent;
  transition: border-color 0.2s;
  font-size: 14px;
}

.search input:focus-visible {
  outline: none;
  border-bottom-color: var(--theme-primary);
}

:global(.gallaryPage.dark) .search::after {
  background-image: url("../../../../public/assets/images/icons/search-dark.png");
}

:global(.gallaryPage.dark) .search input {
  color: var(--text-secondary);
  background-color: var(--gray7);
}

:global(.gallaryPage.dark) .search input:focus-visible {
  border-bottom-color: var(--theme-secondary);
}

@media (max-width: 576px) {
  .search input {
    max-width: unset;
    width: 100%;
  }
}
