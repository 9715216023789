.separator {
  display: flex;
  align-items: center;
  margin: 0.75rem 0;
  font-size: 13px;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #a2a2a2;
}

.separator::after {
  margin-left: 0.25rem;
}

.separator::before {
  margin-right: 0.25rem;
}
