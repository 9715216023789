.icon {
  height: 2rem !important;
  width: 2rem !important;
  display: inline-block;
  align-self: center;
}

.tray-container {
  display: flex;
  flex-direction: column;
  height: 60vh;
  width: 25rem;
  position: fixed;
  background-color: var(--bg-tertiary);
  border-radius: var(--border-radius-secondary);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0.75rem 1rem;
  z-index: 10;
}

.tray-container.fadeIn {
  animation: fadeIn 0.3s;
}

.tray-container :global(.nav-item) {
  flex: 1;
}

.tray-container :global(.nav-item) button {
  text-align: center;
  font-size: 15px;
  width: 100%;
}

.tray-container :global(.nav-link.active) {
  border-bottom-width: 2px !important;
}

.tabs + :global(.tab-content) {
  padding: 0 0.75rem;
  flex: 1;
  overflow-y: auto;
  margin-top: 0.5rem;
}

.tabs + :global(.tab-content)::-webkit-scrollbar {
  width: 4px;
}

.group::after {
  content: "";
  display: block;
  height: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: var(--gray6);
}

.group:last-child::after {
  display: none;
}

.arrow-reverse {
  display: block;
  height: 8px !important;
  transform: rotate(90deg);
}

.notification-item {
  border-bottom: 1px solid var(--gray4);
  gap: 0.75rem;
  padding: 10px 0;
}

.notification-item p,
.notification-item span {
  color: var(--text-primary) !important;
}

.notification-item img {
  height: 3rem !important;
  width: 3rem !important;
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 1;
}

.show-more {
  padding: 0.75rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 992px) {
  .tabs {
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .icon {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }

  .tray-container {
    height: 75vh;
  }

  .notification-item img {
    height: 42px !important;
    width: 42px !important;
  }

  .tray-container {
    right: 1rem !important;
    left: 1rem !important;
    width: 90%;
  }
}
