.background-image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-image: url(../../../../../public//assets//images//landing/fa_chain.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.header-text {
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  background: linear-gradient(268.75deg, #3e6588 3.84%, #2887af 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.description-text {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
