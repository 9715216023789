.buttonGroup {
  display: inline-block;
  white-space: nowrap;
}

.buttonGroup button {
  display: inline-block;
}

.buttonGroup:not(:global(.d-flex)) button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.buttonGroup:not(:global(.d-flex)) button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-with-button {
  display: flex;
  align-items: center;
}

.input-with-button input {
  height: 50px;
  width: 100%;
  padding: 0 100px 0 20px;
  border-radius: 6px 0 0 6px;
}

.input-with-button.primary input,
.input-with-button.primary input:focus {
  border: 2px solid var(--theme-secondary);
}

.input-with-button.white input,
.input-with-button.white input:focus {
  border: 1px solid var(--gray1);
}

.input-with-button.tertiary input,
.input-with-button.tertiary input:focus {
  border: 1px solid var(--theme-secondary);
}

.input-with-button input:focus {
  outline: none;
}

.input-with-button input::placeholder {
  font-size: 15px;
  font-weight: 500;
  color: var(--gray2);
}

.input-with-button button {
  height: 50px;
  font-weight: 600;
  padding: 0 20px;
}

.input-with-button button:last-of-type {
  border-radius: 0px 6px 6px 0px;
}

.input-with-button.primary button {
  background: linear-gradient(
    224deg,
    var(--theme-primary) 0%,
    var(--theme-secondary) 100%
  );
  border: 2px solid var(--theme-secondary) !important;
}

.input-with-button.white button {
  background: var(--bg-tertiary);
  border: 1px solid var(--gray1) !important;
}

.input-with-button.tertiary button {
  background: var(--bg-tertiary) !important;
  border: 1px solid var(--theme-secondary) !important;
}

.input-with-button button img {
  width: 22px;
}

.outlined-button {
  background-color: var(--bg-tertiary);
  color: var(--bs-light-blue);
  border: 1px solid var(--bs-light-blue) !important;
  min-width: 187px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 600;
}

.outlined-button:disabled {
  border: 1px solid var(--gray3) !important;
  color: var(--gray3);
}

.defaultButton {
  border-radius: 6px;
  width: 100%;
  background-color: var(--theme-secondary);
  background-image: linear-gradient(
    to right,
    var(--theme-secondary),
    var(--theme-primary)
  );
  color: var(--text-secondary) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  white-space: nowrap;
  font-weight: 500;
}

.gradButton {
  background: linear-gradient(269.05deg, #2341ba 1.29%, #00599b 98.64%);
  border-radius: 6px;
  color: var(--text-secondary);
  font-size: 16px;
  font-weight: 600;
}

.secondaryButton {
  border-radius: 6px;
  background-color: var(--theme-secondary);
  background-image: linear-gradient(
    to right,
    var(--theme-secondary),
    var(--theme-primary)
  );
  color: var(--text-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 3px 5px 1px var(--bs-light-shadow);
  white-space: nowrap;
  padding: 0 3rem;
  font-size: 15px;
  font-weight: 600;
}

.mutedButton {
  border-radius: 6px;
  background-color: var(--gray3);
  color: var(--theme-secondary);
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 3rem;
  font-size: 15px;
  font-weight: 600;
}

.copyButton {
  min-width: 10ch;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.expandable-btn {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  overflow: hidden;
  transition: width 0.5s;
  padding: 9px;
  gap: 9px;
}

.expandable-btn span {
  color: #3e6588 !important;
  font-size: 14px;
  font-weight: 600;
  margin: 0 !important;
}

.expandable-btn img {
  width: 18px;
}

.expandable-btn,
.expandable-btn:focus {
  background-color: var(--bg-tertiary);
  box-shadow: 1px 1px 10px 0 #3e65882e !important;
}

:global(.gallaryPage.dark) .expandable-btn,
:global(.gallaryPage.dark) .expandable-btn:focus {
  background-color: #000000;
  box-shadow: 1px 1px 5px 0 #ffffff52 !important;
}

/* button sizes and other states */

.disabled {
  composes: mutedButton;
  pointer-events: none;
  background-image: none;
}

.xss {
  height: 38px;
}

.xss.rounded {
  border-radius: 19px;
}

.xs {
  height: 40px;
}

.xs.rounded {
  border-radius: 20px;
}

.sm {
  height: 45px;
}

.sm.rounded {
  border-radius: 22.5px;
}

.md {
  height: 50px;
}

.md.rounded {
  border-radius: 25px;
}

@media screen and (max-width: 768px) {
  .outlined-button {
    min-width: 150px;
  }
}
