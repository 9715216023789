.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  text-align: center;
  z-index: 10005;
}

.loader {
  border: 16px solid #101622;
  border-radius: 50%;
  border-top: 16px solid #2887af;
  width: 100px;
  height: 100px;
  -webkit-animation: spinLoader 2s linear infinite;
  /* Safari */
  animation: spinLoader 2s linear infinite;
}

.loader_container p {
  font-size: 20px;
  margin-top: 16px;
  color: white;
  font-weight: 500;
}

@-webkit-keyframes spinLoader {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinLoader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 50px;
  height: 50px;
}

.spinner .path {
  stroke: rgb(77, 127, 177);
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.suspense-loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  background: #fff;
  box-shadow: -48px 0 #fff, -24px 0 #fff, 24px 0 #fff, 48px 0 #fff;
  box-sizing: border-box;
  animation: shadowPulse 3s linear infinite;
}

.suspense-loader__screen-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes shadowPulse {
  20% {
    background: #fff;
    box-shadow: -48px 0 #3e6588, -24px 0 #fff, 24px 0 #fff, 48px 0 #fff;
  }
  40% {
    background: #fff;
    box-shadow: -48px 0 #fff, -24px 0 #3e6588, 24px 0 #fff, 48px 0 #fff;
  }
  60% {
    background: #3e6588;
    box-shadow: -48px 0 #fff, -24px 0 #fff, 24px 0 #fff, 48px 0 #fff;
  }
  80% {
    background: #fff;
    box-shadow: -48px 0 #fff, -24px 0 #fff, 24px 0 #3e6588, 48px 0 #fff;
  }
  100% {
    background: #fff;
    box-shadow: -48px 0 #fff, -24px 0 #fff, 24px 0 #fff, 48px 0 #3e6588;
  }
}
