.checkList {
  margin-bottom: 0;
}

.checkList input + label {
  display: inline-block;
  height: 15px;
  width: 15px;
  border: 1px solid #2887af;
  margin-right: 6px;
}

.infoRibbon {
  background-color: var(--blue1);
  margin: 0 !important;
  padding: 0.5rem 0;
  color: var(--gray7) !important;
  font-size: 15px;
  text-align: center;
}
