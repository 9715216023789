.profile-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.profile-container > div {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.profile-container img {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.profile-container p {
  color: #2887af;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}

.profile-container button {
  background-color: transparent;
  color: #3e6588;
  font-weight: 500;
  font-size: 12px;
}

.profile-container button img {
  height: 20px;
  width: 20px;
  margin-right: 0.25rem;
  border-radius: 0;
}

.profile-container button span {
  vertical-align: middle;
}

.storage-container {
  box-shadow: 1px 2px 11px rgba(62, 101, 136, 0.29);
  border-radius: 5px;
  padding: 0 1.5rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.storage-container div {
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 1.5rem 0;
}

.storage-container img,
.storage-modal img {
  height: 30px;
}

.storage-container p,
.storage-modal p {
  margin: 0 !important;
  color: #3e6588 !important;
  font-size: 14px;
  font-weight: 500 !important;
  text-align: left !important;
}

p.full-storage,
p.full-storage span {
  color: #be1e2d !important;
  color: #be1e2d !important;
}

.storage-modal p {
  font-size: 15px !important;
}

.storage-container div span,
.storage-modal p span {
  font-size: 20px;
  font-weight: 600;
}

.storage-container hr {
  height: 40px;
  width: 1px;
  background: #3e6588;
  opacity: 1;
  margin: 0 1rem;
}

.storage-modal {
  padding: 2rem;
  position: relative;
}

.storage-modal h1 {
  margin-bottom: 1rem;
}

.storage-modal > div {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.utilization {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.utilization > div {
  display: flex;
  gap: 1rem;
}

.utilization-vdo {
  display: flex;
  gap: 1rem;
}

.accordion {
  padding: 18px 0 !important;
  font-size: 14px;
  font-weight: 600;
  box-shadow: none !important;
  color: #2887af !important;
}

.linebreak {
  display: none;
}

@media screen and (max-width: 460px) {
  .linebreak {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 1550px) {
  .storage-container {
    flex-direction: column;
    padding: 1rem;
    align-items: flex-start;
    gap: 1rem;
  }

  .storage-container div {
    padding: 0;
  }

  .storage-container hr {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .utilization {
    flex-direction: row;
  }

  .utilization > div {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 768px) {
  .storage-modal {
    padding: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .storage-container {
    flex-direction: column;
    padding: 1rem;
    align-items: flex-start;
    gap: 1rem;
  }

  .storage-container div {
    padding: 0;
  }

  .storage-container hr {
    display: none;
  }
}
