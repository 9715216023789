.searchBox {
  margin-right: 0.5rem;
  outline: none;
  height: 2.5rem;
  border-radius: 4px;
  padding: 0 10px;
  max-width: 200px;
  width: 80% !important;
  font-weight: 600;
  color: #3e6588;
  border: 2px solid rgba(40, 135, 175, 1);
}
.searchBox:hover,
.searchBox:focus {
  box-shadow: 3px 2px 4px 0 rgb(40 135 175 / 12%);
}
