.upload-card {
  border: 1px dashed var(--theme-secondary);
  height: 180px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--gray1);
  background-color: var(--bg-tertiary);
  gap: 0.75rem;
}

.instructions {
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  color: var(--gray1) !important;
  margin: 0 !important;
}

.image-preview {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  padding: 1rem;
}
