:root {
  --docsearch-primary-color: #2887af;
}

.search {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 100;
}

.search img {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.search :global(.DocSearch) {
  visibility: hidden;
  height: 0;
  width: 0;
}

:global(.DocSearch-Container) {
  z-index: 2000;
}

@media screen and (max-width: 992px) {
  .search img {
    height: 44px;
    width: 44px;
  }
}
